<template>
    <v-container fluid>
        <v-row class="mb-3" jusitfy="center">
            <v-col v-if="!isMobile">
                <h1 class="text-h5 font-weight-medium">Asistentes Especiales</h1>
            </v-col>
            <v-col class="text-end">
                <v-btn color="primary" @click="dialog = true" block depressed>Agregar Asistente</v-btn>
            </v-col>
        </v-row>

        <v-progress-linear :active="isLoading" indeterminate color="primary" />

        <v-simple-table>
            <template v-slot:default>
                <tbody>
                    <tr v-for="item, index in items" :key="index">
                        <td>{{ item.uid }}</td>
                        <td class="text-end">
                            <v-btn :loading="item.loading" @click="onRemove(index, item.uid)" color="red" text
                                small>Eliminar</v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <CreateDialog v-model="dialog" @result="onResult" />
    </v-container>
</template>

<script>
import { getAllVipAttendees, removeAttendeeVip } from "@/api/attendees";
import CreateDialog from './VipCreate.vue'

export default {
    components: { CreateDialog },

    data: () => ({
        items: [],
        dialog: false,
        isLoading: false,
    }),

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
    },

    methods: {
        onResult(params) {
            this.items.push({ uid: params, vip: true, loading: false })
        },

        async onRemove(index, uid) {
            try {
                this.items[index].loading = true;
                await removeAttendeeVip(uid);
                this.items.splice(index, 1);
            } catch (error) {
                console.error(error)
                this.items[index].loading = false;
            }
        },

        async fetchData() {
            try {
                this.isLoading = true;
                this.items = await getAllVipAttendees();
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;
            }
        }
    },

    mounted() {
        this.fetchData();
    },
}
</script>